@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html,
body,
html * {
  font-family: "Roboto", sans-serif;
}

#centerButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.AddContact {
  background-color: #ffd52b;
  border: none;
  border-radius: 6px;
  width: 200px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  font-weight: 600;
  
}
.AddContact:hover {
  background-color: #ffe992 !important;
}
.AddContact:active {
  background-color: #f3c816;
}

#UserImage {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  align-content: center;
  position: absolute;
  padding-right: 10%;
  width: 100%;
  overflow: inherit;
}
#ProfileImage {
  margin-top: 2%;
  width: 100px;
  height: 100px;
  position: absolute;
}
#UserName {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Helvetica";
  font-size: 18px;
  color: #414141;
  margin-bottom: 4%;
  margin-top: 25%;
}
input,
textarea, select {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  height: 40px;
  padding-left: 4.5%;
  font-weight: 100;
  font-size: 14px;
  font-family: "Helvetica";
  filter: drop-shadow(0 0 0 rgb(0, 0, 0, 0.08));
  color: #414141;
  font-weight: 300;
}
.FieldAuto {
  width: 100%;
  font-weight: 100;
  border-width: 0px;
  border-radius: 6px;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border: 0px solid #c4c4c4;
  border-radius: 6px;
  display: -webkit-inline-flex;
  position: relative;
  color: #c4c4c4;
  padding-left: 4.5%;
  font-weight: 100;
  font-size: 14px;
  font-family: "Helvetica";
  filter: drop-shadow(0 0 0 rgb(0, 0, 0, 0.08));
  font-weight: 300;
  height: auto;
}

.css-16awh2u-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  font-weight: 300;
  font-size: 13px;
  font-family: "Roboto";
  color: #414141;
  border-color: #ffd52b;
}

.Titulos {
  margin-top: 2%;
  margin-left: 4%;
  margin-bottom: 0px;
  font-family: "Helvetica";
  font-size: 14px;
  font-weight: 500;
  color: #414141;
}
::placeholder {
  color: #c4c4c4;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: #c4c4c4;
}

.container2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: #c4c4c4;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
}

.container2 input {
  margin-top: 1%;
  width: 35%;
  min-width: 30%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
}

.tag {
  display: flex;
  align-items: center;
  margin: 10px 0;
  margin-left: 2%;
  margin-right: 1%;
  padding: 0 10px;
  padding-right: 5px;
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  height: 22.5px;
  font-family: "Helvetica";
  font-size: 11px;
}

.tag button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: black;
}

.defaultTag {
  align-items: center;
  margin: 7px 0;
  margin-right: 3%;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  width: 90px;
  font-family: "Helvetica";
  cursor: pointer;
  font-size: 11px;
}

#Default {
  margin-top: 2%;
}

#color1 {
  background-color: #c9dbcd;
  color: #3ba452;
}

#color2 {
  background-color: #b8dbea;
  color: #3090b9;
}

#color3 {
  background-color: #d6a8a8;
  color: #bf2929;
}

#color4 {
  background-color: #ffc188;
  color: #db7600;
}
#color5 {
  background-color: #c4bcdb;
  color: #633ba4;
}
#color6 {
  background-color: #fae1ef;
  color: #ba4d8e;
}
#color7 {
  background-color: #f2ecb7;
  color: #af9e09;
}
#color8 {
  background-color: #ffc9f3;
  color: #d854bb;
}
#colorTransparent {
  background-color: transparent;
  color: transparent;
}
#tagColor {
  font-family: "Roboto";
  color: #ffffff;
  background: #414141;
}

.hide {
  display: None;
}
#iconTag {
  color: #414141;
  margin-left: 2.5%;
  margin-top: 2.5%;
  width: 24px;
  height: 24px;
}
#addTag {
  color: #414141;
  position: fixed;
  display: flex;
  float: right;
  margin-left: 80%;
  margin-top: 2%;
  cursor: pointer;
}
#cerrar {
  position: relative;
  display: flex;
  float: right;
  color: #414141;
  margin-top: 3%;
  cursor: pointer;
  z-index: 1000;
}
#foto {
  position: relative;
  display: flex;
  color: #414141;
  margin-top: 19%;
  margin-left: 13%;
  cursor: pointer;
  z-index: 9999999;
  background-color: white;
  border-radius: 90%;
  width: 30px;
  height: 30px;
  justify-content: center;
}
#fotoSize {
  margin-top: 10%;
  margin-left: 5%;
}

#focus {
  border-color: #ffd52b;
}
#errorValidation {
  border-color: #c90505;
}

#confirmNotVisible {
  color: #414141;
  position: fixed;
  display: flex;
  float: right;
  margin-left: 80.5%;
  margin-top: -6.5%;
  width: 24px;
  height: 24px;
  visibility: hidden;
}
#errorNotVisible {
  color: #c90505;
  position: fixed;
  display: flex;
  float: right;
  margin-left: 80.5%;
  margin-top: -6.5%;
  width: 24px;
  height: 24px;
  visibility: hidden;
}
#confirmVisible {
  color: #414141;
  position: fixed;
  display: flex;
  float: right;
  margin-left: 80.5%;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
}
#errorVisible {
  color: #c90505;
  position: fixed;
  display: flex;
  float: right;
  margin-left: 80.5%;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
}

#centerTitle {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Helvetica";
  font-size: 18px;
  color: #414141;
  margin-bottom: 4%;
  margin-top: 25%;
}

#fotoEliminar {
  width: 100px;
  height: 100px;
}
#divFotoEliminar {
  justify-content: center;
  display: flex;
}
#confirmDelete {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: "Helvetica";
  font-size: 18px;
  color: #414141;
  margin-bottom: 4%;
  margin-top: 5%;
}

#errorMessagePassword {
  background-color: #ffffff;
  margin-left: 4%;
  padding-left: 1%;
  padding-top: 5%;
  margin-bottom: 0px;
  font-family: "Helvetica";
  font-size: 12px;
  font-weight: 400;
  color: #bf2929;
  width: 90%;
  border: 1px solid #d38b8b;
  border-radius: 10px;
  height: 50px;
  margin-top: -20px;
  display: none;
}

#ErrorMessagePasswordShow {
  background-color: #ffffff;
  margin-left: 4%;
  padding-left: 1%;
  padding-top: 5%;
  margin-bottom: 0px;
  font-family: "Helvetica";
  font-size: 12px;
  font-weight: 400;
  color: #bf2929;
  width: 90%;
  border: 1px solid #d38b8b;
  border-radius: 10px;
  height: 50px;
  margin-top: -20px;
}

#requisitos {
  margin-left: 3%;
  display: flex;
}

#paquitoContactos {
  width: 18%;
  margin-bottom: 5%;
}
#fotoPaco {
  display: flex;
  justify-content: center;
}
#messagePaco {
  width: 340px;
  height: 150px;
  border-radius: 16px;
  filter: drop-shadow(0 0 0 rgb(0, 0, 0, 0.16));
  box-shadow: 0px 3px 14px lightgrey;
  display: flex;
  justify-content: center;

  padding-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
  margin-top: -118px;
  margin-bottom: 5%;
  font-size: 13px;
  font-family: "Helvetica";
  align-items: center;
  vertical-align: top;
  font-weight: 500;
  color: #414141;
  text-align: center;
}
#justifyPaco {
  justify-content: center;
  position: relative;
  display: flex;
}
#inlineDiv {
  margin-top: 27%;
  position: relative;
  display: flex;
  margin-bottom: 17%;
}
#NombreProyecto {
  width: 100%;
  position: absolute;
}
#NumeroId {
  width: 30%;
  position: absolute;
  margin-left: 70%;
  margin-top: 0.6%;
  padding-bottom: 20px;
}
textarea {
  padding-top: 2.5%;
  height: 125px;
}
#confirmVisibleNombre {
  color: #414141;
  position: fixed;
  display: flex;
  float: right;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
  margin-left: 81.5%;
}
#errorVisibleNombre {
  color: #c90505;
  position: fixed;
  display: flex;
  float: right;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
  margin-left: 81.5%;
}
#confirmVisibleId {
  color: #414141;
  position: fixed;
  display: flex;
  float: right;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
  margin-left: 20%;
}
#DivTitleEliminar {
  text-align: center;
  display: flex;
  position: relative;
}
#DivTitleEliminar2 {
  text-align: center;
  display: flex;
  position: relative;
  font-family: Helvetica;
  font-weight: 300;
  font-size: 13px;
  margin-left: 10%;
  margin-right: 10%;
}
#errorVisibleId {
  color: #c90505;
  position: fixed;
  display: flex;
  float: right;
  width: 24px;
  height: 24px;
  margin-top: -6.5%;
  margin-left: 20%;
}
#centerTitle2 {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Helvetica";
  font-size: 18px;
  color: #414141;
  margin-bottom: 4%;
  margin-top: 9%;
}
#linePress {
  position: relative;
  display: flex;
  justify-content: left;
  margin-left: 3%;
  font-family: "Helvetica";
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin-top: 4px;
}
#linePress2 {
  position: relative;
  display: flex;
  justify-content: left;
  margin-left: 3%;
  font-family: "Helvetica";
  font-weight: 300;
  font-size: 12px;
  color: black;
  margin-top: 4px;
}
#inputGasto {
  width: 40px;
  height: 40px;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  font-family: "Helvetica";
  color: #c4c4c4;
}
#inputGasto2 {
  width: 22px;
  height: 17px;
  justify-content: center;
  border: 0.5px solid #414141;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-family: "Helvetica";
  color: #414141;
}
#mtline {
  margin-top: 10px;
  margin-left: 10px;
}
#mtline2 {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 8px;
}
.mlGI {
  margin-right: 45px;
  font-family: "Helvetica";
  justify-content: center;
  text-align: center;
}
.mlGI2 {
  margin-right: 25px;
  font-family: "Helvetica";
  justify-content: center;
  text-align: center;
}
.mlGG {
  margin-right: 26px;
  font-family: "Helvetica";
}
.mlBI {
  margin-right: 15px;
  font-family: "Helvetica";
}
.mlGG2 {
  margin-right: 19px;
  font-family: "Helvetica";
}
.mlBI2 {
  margin-right: 28px;
  font-family: "Helvetica";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.marginTableRows{
  justify-content:center; 
  margin-top:0px !important;
  width:100%;
}

.firstp {
  margin-top: 10px;
  width:170px;
  height:27px;
  border:1px solid #D9D9D9;
  border-right: none;
  margin:0px;
  background-color:#F5F5F5;
  padding:5px 0px 0px 15px;
  border-radius: 0px 0px 0px 0px;
  font-size:12px;
  line-height: 14px;
}
.lastp {
  margin-top: 10px;
  width:170px;
  height:27px;
  border:1px solid #D9D9D9;
  border-left: none;
  margin:0px;
  background-color:#F5F5F5;
  padding:5px 0px 0px 15px;
  border-radius: 0px 0px 0px 0px;
  font-size:12px;
  line-height: 14px;
}

.marginTableRows input{
  color: #414141 !important;; 
  font-size:14px;
  border:1px solid #D9D9D9;
  font-weight:400;
  border-right: none;
  padding-top:1px; 
  border-radius: 0px;
  height:27px !important; 
  width:39px !important;
}




/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#cerrar2 {
  position: absolute;
  display: flex;
  margin-left: 230px;
  float: right;
  color: #414141;
  margin-top: 6.5%;
  cursor: pointer;
  z-index: 1000;
}

_::-webkit-full-page-media,
_:future,
:root #confirmNotVisible {
  margin-left: -35px;
  margin-top: 1.7%;
}
_::-webkit-full-page-media,
_:future,
:root #errorNotVisible {
  margin-left: -35px;
  margin-top: 1.7%;
}

_::-webkit-full-page-media,
_:future,
:root #confirmVisible {
  margin-left: -35px;
  margin-top: 2%;
}
_::-webkit-full-page-media,
_:future,
:root #errorVisible {
  margin-left: -35px;
  margin-top: 1.7%;
}
_::-webkit-full-page-media,
_:future,
:root #errorVisibleNombre {
  margin-left: -35px;
  margin-top: 1.7%;
}

_::-webkit-full-page-media,
_:future,
:root #errorVisibleId {
  margin-left: -35px;
  margin-top: 1.7%;
}

_::-webkit-full-page-media,
_:future,
:root #confirmVisibleNombre {
  margin-left: -35px;
  margin-top: 1.7%;
}

_::-webkit-full-page-media,
_:future,
:root #confirmVisibleId {
  margin-left: -35px;
  margin-top: 1.7%;
  z-index: 99;
}

.settingsProject {
  margin-top: -5px;
  margin-left: -15px;
  width: 15px;
  height: 15px;
}


.principal:hover .secondary {
  visibility: visible !important;
}