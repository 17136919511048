.thumbUp{
    /* color: #00CC00;  */
    color: #A1A1A1;
    opacity: 0.4;
}

.thumbUp:hover{
    color: #4FE64F;
    opacity: 1;
}

.refresh{
    color: #A1A1A1;
    opacity: 0.4;
}

.refresh:hover{
    color: #8DC6E6;
    opacity: 1;
}

.thumbDown{
    /* color: #CC0000; */
    color: #A1A1A1;
    opacity: 0.4;
}

.thumbDown:hover{
    color: #FF4F4F;
    opacity: 1;
}


#thumbUpSelected{
    color: #00CC00;
    opacity: 1;
}

#thumbDownSelected{
    color: #CC0000;
    opacity: 1;
}
