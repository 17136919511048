.hbspt-form{
    justify-content: center;
    display: flex;
}

#hbspt-form-7bdbc22b-8295-40e3-88ae-b4d76b69bc83{
    justify-content: center;
    display: flex;
}

@media screen and (max-width: 900px) {
    .LayoutHubspot{
        margin-left: 0px !important;
    }
    .AnimationHubspot{
        display: none !important;
    }
    #BlockHubspot{
        display: block !important;
    }
    .div100{
        width:98% !important;
    }
    .divContainerHubspot{
       overflow: auto;
    }
    .autoVariableWidth{
        width: 90% !important;
    }
    #overflowDropdown{
        width: 100% !important;
        margin-right: 0px !important;
    }
    #overflowInputDrop{
        overflow: hidden;
        height: 12px;
        margin-left: 0px;
    }
    .css-i4bv87-MuiSvgIcon-root{
        right: -3px !important;
        z-index: 99;
    }
    #dropDownWidth{
        width: 103% !important;
    }
    #dropDownWidth2{
        width: 101% !important;
    }

}
    
