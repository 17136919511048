.CardProjects {

  border-radius: 6px;
  
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin-bottom: 24px;
  margin-top: 1.25rem;
  width: 380px;
  margin-left: 5%;
  /*
  transition: transform .2s;
  */
}
.CardProjects2 {
  border: none;
  border-radius: 6px;
 
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin-bottom: 24px;
  margin-top: 1.25rem;
  width: 360px;
  margin-left: 5%;
  margin-right: 20px;
}
.CardProjects2:hover, .CardProjects:hover {
  background-color: #FFE99259;
  transform: scale(1.05);
  transition: transform .2s;
    
}

.CardProjects:hover .dropdown-menu-end{
  transform: scale(0.9) !important;
  z-index: 9999;
}

.CardProjects:hover ~ div .d-inline-block #settingb{
  transform: scale(1.2);
  transition: transform .2s;
}

.DropdownContainer:hover #settingb{
  transform: scale(1.2);
}

.CardProjects:hover > #settingb{
  transform: scale(1.1);
  transition: transform .2s;
}

.CardProjectsDisabled {
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin-bottom: 24px;
  margin-top: 1.25rem;
  width: 380px;
  margin-left: 5%;
  opacity: 0.5;
}

.cardWithHover:hover {
  background-color: #FFE99259;
}

#CardContent {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
  height: 120px;
}
#CenterContentCard {
  display: flex !important;
  justify-content: center;
  padding-top: 7%;
  font-family: "Helvetica";
  color: #414141;
  font-weight: 500;
  font-size: 14px;
}
#AddProyecto {
  margin-right: 25px;
  position: relative;
  font-family: "Helvetica";
  color: #414141;
  font-weight: 500;
  font-size: 14px;
}
#JustifyDiv {
  padding-top: 10px;
  font-family: "Helvetica";
}
.ImgProyect {
  height: 5rem;
  width: 5rem;
  position: absolute;
}
#CenterContentProyectCard {
  display: flex !important;
  justify-content: center;
  padding-top: 1%;
  font-family: "Helvetica";
  color: #414141;
  font-weight: 500;
  font-size: 14px;
  margin-left: 27%;
  max-height: 90px;
}
.DescriptionText {
  font-size: 14px;
  font-weight: 300;
  font-family: Helvetica;
  color: #414141;
  margin-right: 20px;
}
#Estrella {
  position: absolute;
  display: flex;
  margin: 52px;
}
#SwitchVista {
  position: relative;
  display: flex;
  float: right;
  background-color: #f3f3f3;
  border-radius: 6px;
  width: 80px;
  height: 30px;
}
#vistaCuad {
  background-color: #ffd52b;
  padding-top: 3%;
  margin-left: 3%;
  border-radius: 6px !important;
  width: 40px;
  justify-content: center;
  position: relative;
  display: flex;
  color: #414141;
}
#vistaList {
  background-color: #f3f3f3;
  padding-top: 3%;
  margin-left: 3%;
  border-radius: 6px !important;
  width: 40px;
  justify-content: center;
  position: relative;
  display: flex;
  color: lightgray;
  cursor: pointer;
}
#vistaList:hover {
  background-color: #ffe992;
  border-radius: 6px !important;
}
#vistaCuad2 {
  background-color: #f3f3f3;
  padding-top: 3%;
  margin-left: 3%;
  border-radius: 6px !important;
  width: 40px;
  justify-content: center;
  position: relative;
  display: flex;
  color: lightgray;
  cursor: pointer;
}
#vistaCuad2:hover {
  background-color: #ffe992;
  border-radius: 6px !important;
}
#vistaList2 {
  background-color: #ffd52b;
  padding-top: 3%;
  margin-left: 3%;
  border-radius: 6px !important;
  width: 40px;
  justify-content: center;
  position: relative;
  display: flex;
  color: #414141;
}
#addContact {
  color: #414141;
  position: absolute;
  display: flex;
  float: right;
  margin-left: 72%;
  margin-top: -40px;
  cursor: pointer;
}
.css-jbay4l-MuiDataGrid-root .MuiDataGrid-cell--textLeft:first-child {
  cursor: pointer;
}
.react-pdf__Page__canvas{
  min-height: 85vh;
  min-width: 55vh;
}
.importantMargin{
  margin-left: 5px !important;
}
textarea::placeholder {
  color: #b7b7b7;
  font-family: Helvetica;
  font-weight: 300;
}

#testPage{
  width: 100% !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding-right: 81px;
}
.MuiAutocomplete-hasPopupIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root{
  height: 40px;
}
.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 7.5px 6px;
}

#tableProjectTitles {
  font-family: "Helvetica";
  font-weight: 500;
  color: #414141;
  font-size: 13px;
}
.tableProjectName {
  font-family: "Helvetica";
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}
.tableOtherName {
  font-family: "Helvetica";
  font-weight: 300;
  color: #000000;
  font-size: 14px;
}
.tableDateName {
  font-family: "Helvetica";
  font-weight: 300;
  color: #c4c4c4;
  font-size: 14px;
}
#gridBudget{
  overflow-y:auto;
}
.AddProjectPdf {
  background-color: #ffd52b;
  border: none;
  border-radius: 6px;
  width: 145px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  font-weight: 600;
  position: fixed;
  bottom: 25px;
  margin-right: 5px;
  z-index: 100;
}
.AddProjectPdf2 {
  background-color: #ffd52b;
  border: none;
  border-radius: 6px;
  width: 145px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  font-weight: 600;
  position: fixed;
  bottom: 25px;
  margin-right: 5px;
  z-index: 100;
}
@media (max-height: 740px) {
  .AddProjectPdf {
    margin-left: 20px !important;
    position: relative;
  }
}
.AddProject {
  background-color: #ffd52b;
  border: none;
  border-radius: 6px;
  width: 145px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  font-weight: 600;
  position: fixed;
  bottom: 25px;
  margin-right: 5px;
  z-index: 100;
}
.AddProject:hover {
  background-color: #ffe992;
}
.AddProject:active {
  background-color: #f3c816;
}
#ProjectBudgetCard {
  height: 80px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%),
    0px 1px 14px 0px rgb(0 0 0 / 7%);
  border-radius: 6px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #ffffff;
}

#textodebudgeting {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #a0aec0;
}
#euro {
  align-items: center;
  display: flex;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #414141;
}
#percentage {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #48bb78;
}
#todoelcontenido {
  display: flex;
  position: relative;
  padding-top: 25px;
}
#divTotalProyectos {
  height: 900px;
  position: relative;
  display: flex;
  padding-top: 100px;
  width: 100%;
  overflow-x: scroll;
}
#editarProyectos {
  width: 600px;
  position: absolute;
  display: flex;
}
#divPresupuestos {
  margin-left: 460px;
  position: relative;
  display: initial;
  margin-top: -80px;
}
#divactasyordenes {
  margin-left: 40px;
  position: relative;
  display: initial;
  width: 387px;
  margin-top: -80px;
}
#ActasBudgetCard {
  width: 389px;
  height: 555px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%),
    0px 1px 14px 0px rgb(0 0 0 / 7%);
  border-radius: 6px;
  padding: 1rem;
  background-color: #ffffff;
}

#muypronto {
  width: 60px;
  height: 13px;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 9px;
  color: white;
  background-color: #002ad4;
  border-radius: 2px;

  float: right;
  display: flex;
  position: relative;
  justify-content: center;
  margin-right: 10px;
}
#projectOverflow {
  position: relative;
  display: inline-flex;
  width: auto;
  margin-left: 35px;
}
#TareasBudgetCard {
  width: 389px;
  height: 555px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%),
    0px 1px 14px 0px rgb(0 0 0 / 7%);
  border-radius: 6px;
  padding: 1rem;
  background-color: #ffffff;
}
#EquiposBudgetCard {
  width: 389px;
  height: 115px;
  margin-top: 2rem;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%),
    0px 1px 14px 0px rgb(0 0 0 / 7%);
  border-radius: 6px;
  padding-left: 20px;
  padding-top: 15px;
  background-color: #ffffff;
}
.AddProjectDisabled {
  background-color: #efefef;
  border: none;
  border-radius: 6px;
  width: 163px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #495057;
  font-weight: 600;
  box-shadow: 0px 3px 4px lightgray;
}
#SubMenuProjects {
  position: relative;
}
.marginAndDispoistion {
  font-family: "Helvetica";
  font-weight: 300;
  font-size: 15px;
  color: #414141;
  padding-right: 45px;
}
.marginAndDispoistionSelected {
  font-family: "Helvetica";
  font-weight: 500;
  font-size: 15px;
  color: #414141;
  padding-right: 45px;
}
#totalDiv {
  height: 650px;
  display: flex;
  justify-content: center;
  position: relative;
}
#centradoBudget {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  font-weight: 600;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  margin-bottom: 4%;
  margin-top: 5%;
}
#divFotoBudget {
  justify-content: center;
  display: flex;
  margin-top: 5%;
}
#focusLittle {
  border-color: #ffd52b;
  width: 40px;
  height: 40px;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  font-family: "Helvetica";
  color: #c4c4c4;
}
#focusLittle2 {
  border-color: #ffd52b;
  width: 22px;
  height: 17px;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-family: "Helvetica";
  color: #414141;
}
#focusError2 {
  border-color: #bf2929;
  width: 22px;
  height: 17px;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  font-family: "Helvetica";
  color: #414141;
}
#focusError {
  border-color: #bf2929;
  width: 40px;
  height: 40px;
  justify-content: center;
  padding-left: 16px;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: "Helvetica";
  color: #c4c4c4;
}
.lightPoppins {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #9f9d9d;
}

_::-webkit-full-page-media,
_:future,
:root #OtroConten {
  padding-top: 15%;
}

_::-webkit-full-page-media,
_:future,
:root textarea {
  position: -webkit-sticky;
}

#OpenGoogle {
  width: 100%;
  height: 35px;
  border-radius: 6px;
  border: 1px solid #c4c4c4;
  background-color: white;
  margin-bottom: 15px;
}

#imgGoogle {
  width: 22px;
  width: 22px;
  margin-right: 10px;
}

#forgotPass {
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: -3px;
  padding-bottom: 10px;
  cursor: pointer;
}
.dropzone2 {
  min-height: 230px;
  border: 2px dashed #ffd52b;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
}
#centerPaco {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: -20px;
  margin-top: 40px;
}
#muypronto2 {
  width: 60px;
  height: 13px;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 9px;
  color: white;
  background-color: #002ad4;
  border-radius: 2px;

  float: left;
  display: flex;
  position: absolute;
  justify-content: center;
  margin-left: 15px;
  z-index: 2;
  rotate: -15deg;
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  transform: rotate(15deg);
}
#PopUpProject {
  position: absolute;
  margin-left: 431px;
  margin-top: -78px;
  width: 200px;
  height: 120px;
  font-family: "Helvetica" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #414141;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: none;
}
.textPopUpProject {
  font-family: "Helvetica" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #414141;
  height: 35px;
  padding-left: 16px;
  padding-top: 9px;
  border-radius: 6px;
}
.textPopUpProject:hover {
  background-color: #efefef;
  cursor: pointer;
}
#PopUpProjectShow {
  position: absolute;
  margin-left: 484px;
  margin-top: -4px;
  width: 200px;
  height: 35px;
  font-family: "Helvetica" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #414141;
  background-color: white;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 10%);
  border-radius: 6px;
  z-index: 3;
}
.nuevoEnd {
  position: absolute;
  margin-left: 335px;
  margin-top: 22px;
  cursor: pointer;
}
#cerrarPopUp {
  position: absolute;
  width: 20px;
  margin-left: 175px;
  margin-top: 5px;
  cursor: pointer;
}
.CancelDel2 {
  margin-top: 1%;
  background-color: #ffffff;
  border: 2px solid #ffd52b;
  border-radius: 6px;
  width: 145px !important;
  height: 30px;
  font-family: "Helvetica";
  font-size: 14px;
  color: #495057;
  font-weight: 500;
  position: absolute;
  margin-left: -160px;
}
.CancelDel2:hover {
  background-color: #ffe992;
  border: 1px solid #ffd52b;
}
.CancelDel2:active {
  background-color: #f3c816;
  border: 1px solid #ffd52b;
}
#addBase {
  position: relative;
  margin-left: -9px;
  margin-right: 3px;
}
.posPronto {
  position: absolute !important;
  margin-left: 300px !important;
  margin-top: 100px !important;
}

#Poppinsnormal {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;

  color: #414141;
}

.css-julti5-MuiSwitch-root {
  margin-top: -9px;
  margin-left: 30px;
}
#nonHover:hover{
  background-color: transparent !important;

}
.aaa {
  margin-top: -9px;
  margin-left: 4px;
}


.butonAceptar:hover{
    background: rgba(59, 164, 82, 0.19);
    border: 1px solid #3BA452;
    border-radius: 6px;
}

.butonAceptar{
  background-color: white;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
}


.butonAceptar2{
  background: white;
  border: 1px solid #4461D7;
  border-radius: 6px;
font-family: "Helvetica";
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 15px;
display: flex;
align-items: center;
}


.recuadro {
  width: 125px;
  background: #414141;
  border-radius: 2px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  justify-content: center;
  padding: 4px;
}

.recuadroPico {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #414141;
  position: absolute;
  top: -6px; /* Adjust the position to align with the tooltip */
}

.primerIcono, .segundoIcono, .tercerIcono, .cuartoIcono, .quintoIcono, .sextoIcono {
display: none;
}
.primerIconoShow:hover + .primerIcono{
  display: block;
}

.segundoIconoShow:hover + .segundoIcono{
  display: block;
}

.editarPartidaDiv:hover {
  z-index: 1;
}

.tercerIconoShow:hover + .tercerIcono{
  display: block;
}

.cuartoIconoShow:hover + .cuartoIcono{
  display: block;
}

.quintoIconoShow:hover + .quintoIcono{
  display: block;
}

.sextoIconoShow:hover + .sextoIcono{
  display: block;
}

.icono {
  display: none;
}

.iconoShow:hover + .icono {
  display: block;
}

.selectDrop:hover{
  border-bottom: 2px solid #5984FC !important;
}
.selectDrop:hover p{
  font-weight: 600 !important;
}

#importantLeft{
  left: 10px !important;
}

.container4buttons{
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  position: relative;
  justify-content: center;
  width: 262px;
  height: 30px;
}

.css-1s2u09g-control{
  height: 45px !important;
  background-color: white !important;
  padding-top: 0px !important;
}

.css-14el2xx-placeholder, .css-1hb7zxy-IndicatorsContainer, .css-qc6sy-singleValue{
  margin-top: -9px !important;
}

.css-1u9des2-indicatorSeparator {
  height: 35px !important;
}
.css-13cymwt-control{
  height: 35px !important;
  min-height: 35px !important;
  border-radius: 6px !important;
  border-color: #c4c4c4 !important;
}
.css-1fdsijx-ValueContainer, .css-hlgwow{
  margin-top: -7px !important;
}

.css-qbdosj-Input{
  padding-top: 0px !important;
}

.css-1pahdxg-control:hover, .css-1pahdxg-control:focus, .css-1pahdxg-control{
  border-color: #ffd52b !important;
}

.hoverYellow:hover{
  background-color: #ffe992 !important;
}

.hoverRow:hover .hoverIcon{
  display: block !important;
}

.css-1pahdxg-control{
  box-shadow: none !important;
  height: 45px !important;
  background-color: white !important;
  padding-top: 0px !important;
}

.css-1pndypt-Input{
  margin-top: -3px !important;
}
.css-vwja0k, .css-1wy0on6{
  margin-top:-9px !important;
}
.css-feqhe6{

  border-radius: 6px;
}

.textToHover:hover{
  font-weight: 500;
  text-decoration: underline;
}

.css-2a2mpe{
  /*margin-left: 8px !important;
*/
}

.square::after{
  content: "";
  display: block;
  padding-bottom: 100%;
}
.importantColor414141{
  color: #414141 !important;
}

.greyCircle {
  border-radius: 100px;
  width: 110px;
  height: 110px;
  background-color: #EFF1F6;
  justify-content: center;
  display: grid;
  align-items: center;
  text-align: center;
  margin-top: 18px;
  padding-top: 3px;
  margin-right: 38px;

}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  opacity: 0.65 !important;
}

.css-q5cu77:hover{
  background-color: #ffe992!important;
}

#menuProfile{
  transform: translate3d(140px, -30px, 0px) !important;
  transition: none !important
}


#bigCellWithOverflow{
  max-width: 149px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 16px 6px 16px !important;

}

#helveticaTitle{
  font-family: Helvetica;
  font-size: 13px;
  font-weight: 700;
  padding: 6px 16px 6px 16px !important;
  line-height: 0px !important;
}

#rowHoverSpecial:hover + #rowHover{
  background-color: #FDEFC6 !important;
}

#rowHover:hover{
  background-color: #FDEFC6 !important;
}


#rowHoverNoColor:hover  #showCheckbox{
  display: block !important;
}

#rowHover:hover  #showCheckbox{
  display: block !important;
}

#specialInputHover{
  display: none;
}

#rowHoverSpecial:hover #specialInputHover{
  display: block !important;
}

#rowHoverSpecial2:hover #specialInputHover{
  display: block !important;
}

#DeleteButton:hover{
  background-color: #F5D2D2 !important;
}

#DeleteButton:active{
  background-color: #D28282 !important;
}
