.rowHeader {
  background-color: white;
  height: 44px;
  border-radius: 6px;
}

.rowHeader:hover {
  background-color: #ccd8fc;
}

.maxWidth35 {
  max-width: 35px;
}

.backgroundLightBlue {
  background-color: rgba(204, 216, 252, 0.42);
}
.backgroundWhite {
  background-color: white;
}

.tableBorderBottom {
  border-bottom: solid rgb(233, 236, 239);
}

.tableBorderRight {
  border-right: 1px solid rgb(183, 183, 183);
}

.tableBorderRight2 {
  border-right: 1px solid rgb(233, 236, 239);
}

.w-4-percent {
  width: 4%;
}

.w-6-percent {
  width: 6%;
}

.w-38-percent {
  width: 38%;
}
