.AcceptButton {
  background-color: #ffd52b;
  border: none;
  border-radius: 6px;
  width: 200px;
  height: 48px;
  font-family: "Helvetica";
  font-size: 16px;
  color: #414141;
  font-weight: 600;
}
.AcceptButton:hover {
  background-color: #ffe992 !important;
}
.AcceptButton:active {
  background-color: #f3c816;
}

.AcceptButton.disabled {
  background-color: #cccccc; /* Color de fondo para el estado deshabilitado */
  color: #666666; /* Color del texto para el estado deshabilitado */
  cursor: not-allowed; /* Cambia el cursor para indicar que está deshabilitado */
}
