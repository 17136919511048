.gantt .grid-header {
  fill: #ffffff;
  stroke: #ccd8fc !important;
  stroke-width: 1px !important;
}

g.date {
  padding: 100px;
}

.gantt .bar-label {
  fill: #414141;
  text-anchor: end;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.gantt .bar {
  fill: #ccd8fc;
  stroke-width: 0;
  transition: stroke-width 0.3s ease;
  user-select: none;
}

.gantt .tick {
  stroke: #ccd8fc;
  stroke-width: 0.5;
}

.gantt .grid-row {
  fill: white !important;
}

.gantt .row-line {
  stroke-width: 0;
}

.gantt .arrow {
  fill: none;
  stroke: #414141;
  stroke-width: 1px;
}

.gantt .upper-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #414141;
}
.gantt .lower-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #b7b7b7;
}

.react-kanban-column-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #414141;
  text-transform: uppercase;
  background: #ffffff;
  margin-bottom: 30px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding-left: 20px;
  padding-top: 3px;
  height: 40px;
  width: 260px;
  border-top: 10px solid lightblue;
}
.react-kanban-column {
  background-color: transparent !important;
}

.react-kanban-card {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  max-width: 260px;
  width: 260px;
}

.react-kanban-card__title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #414141;
  padding-left: 10px;
  border-bottom: none;
}
.react-kanban-card__description {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #414141;
  padding-left: 10px;
  border-bottom: none;
  padding-right: 10px;
}

.react-kanban-board {
  overflow-y: clip !important;
}

.css-cpgvjg-MuiSnackbar-root {
  top: inherit !important;
  left: inherit !important;
  right: inherit !important;
  position: relative !important;
}

div.twitter-picker {
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px !important;
  margin-left: -25px;
}
#gantt {
  height: 700px;
}
.dx-gantt {
  border: none;
}

.dx-gantt .dx-gantt-toolbar-wrapper {
  padding: 0px;
  border-bottom: 1px solid #ccd8fc;
}

.dx-gantt .dx-gantt-toolbar-wrapper .dx-gantt-toolbar-separator {
  border-left: 1px solid transparent;
}

.dx-splitter.dx-splitter-inactive {
  background-color: #ccd8fc;
}

.dx-treelist .dx-column-lines > td {
  border-left: none;
  border-right: none;
}

.dx-treelist .dx-row > td {
  padding-top: 15px;
  font-family: Helvetica;
  font-size: 14px;
}
.dx-gantt .dx-treelist-text-content {
  font-family: Helvetica !important;
  font-size: 14px;
}

.dx-gantt .dx-gantt-altRow,
.dx-gantt .dx-gantt-collapsable-row {
  background-color: white;
  border-left: 5px solid #15b3d6;
}

.dx-gantt .dx-gantt-altRow,
.dx-gantt .dx-gantt-collapsable-row:nth-child(odd) {
  background-color: white;
  border-left: 5px solid #15b3d6;
}
.dx-gantt .dx-gantt-altRow,
.dx-gantt .dx-gantt-collapsable-row:nth-child(even) {
  background-color: white;
  border-left: 5px solid #e95ec2;
}

.dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
  color: #414141;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task.dx-gantt-parent {
  height: 30px;
  line-height: 30px;
  background-color: #ccd8fc;
  border-left: 5px solid #15b3d6;
  border-right-color: #ccd8fc;
  border-top-color: #ccd8fc;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task.dx-gantt-parent:nth-child(odd) {
  border-left: 5px solid #15b3d6;
}
.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task.dx-gantt-parent:nth-child(even) {
  border-left: 5px solid #e95ec2;
}
.dx-gantt .dx-gantt-taskWrapper {
  margin-top: 0px;
}
.dx-gantt .dx-gantt-taskWrapper .dx-gantt-titleIn {
  color: #414141;
  font-family: Helvetica;
  padding: 0 7px;
  font-size: 14px;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task {
  background-color: #e9ecf8;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task .dx-gantt-tPrg {
  background-color: #d6defe;
}
.css-ptiqhd-MuiSvgIcon-root {
  display: none;
}
.navbar.navbar-transparent {
  background-color: #EFEFEF !important;
}
.navbar.navbar-absolute {
  position: fixed;
  z-index: 5;
  margin-left: 60px;
}
.sidebar-mini .navbar.navbar-absolute {
  position: fixed;
  z-index: 5;
  margin-left: 75px;
}
#showUnities {
  margin-top: -40px !important;
}

#showUnities .dropdown-item {
  padding-top: 4px;
  padding-bottom: 5px;
}

.css-1ixds2g {
  height: 4px !important;
}

.css-jbay4l-MuiDataGrid-root .MuiDataGrid-row:hover,
.css-jbay4l-MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: #fff7d9 !important;
}

.css-97alyd .MuiDataGrid-row.Mui-selected:hover,
.css-97alyd .MuiDataGrid-row.Mui-selected.Mui-hovered {
  background-color: #fff7d9 !important;
}

.css-ecvcn9 {
  margin-top: -9px;
}

.css-q5cu77.Mui-selected {
  border: 0px !important;
    background-color: #414141 !important;
    color: white !important;
    font-weight: 700 !important;
}
.css-q5cu77.Mui-disabled{
  border: 1px solid rgba(65, 65, 65, 0.5) !important;
    border-radius: 6px !important;
    color: rgba(65, 65, 65, 0.5) !important;
    font-weight: 400 !important;
}

.css-tv4p6g.Mui-checked {
  color: #4461D7 !important
}
.css-tv4p6g.Mui-checked+.MuiSwitch-track {
  background-color: #4461D7 !important;
}
.css-tv4p6g.Mui-checked + .MuiSwitch-track {
  background-color: #4461D7 !important;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root:hover {
  background-color: #ffe992 !important;
  border: 1px solid #ffd52b !important;
}

.timeline-steps {
  display: flex;
  justify-content: center;
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem;
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: 0.25rem solid #eca771;
    width: 84%;
    position: absolute;
    left: 7.5rem;
    top: 0.3125rem;
  }
  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: 0.25rem solid #eca771;
    width: 84%;
    position: absolute;
    right: 7.5rem;
    top: 0.3125rem;
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center;
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #eca771;
  display: inline-block;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 6.25rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.hoverYellow:hover{
  background-color: #FFE99259 !important;
}

#hoverDropdown:hover p{
  font-weight: 700 !important;
  }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 1.5px;
  top: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffd52b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffd52b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* 
.css-de9k3v-MuiDataGrid-selectedRowCount {
  display: none !important;
} */


.hbspt-form{
  background-color: white;
  margin-top:250px;
}


.css-nfmglb-MuiTableCell-root{
  border-bottom: none !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root{
  height: 40px;
  padding-top: 1px !important;
}

.eApaPk td{
  border-bottom: none !important;
}