@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
    font-family: 'Roboto', sans-serif;
}

/*Estilo general de la tabla con la información del usuario*/
.table-profile{
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 85%;
    margin-bottom: 1rem;
    color: #7A7878;
    vertical-align: center;
    border-color: #dee2e6;
    font-family: "Noto sants";
    font-size: 14px;
    margin-left: 8%;

}
.table-profile th{
    font-size: 17px;
}
.table-profile tr, td{
    height: 50px;
    border-bottom-width: 1px;
    border-bottom-color: #7A7878;
    vertical-align: center;

}
.table-profile td{
    height: 40px;
    font-size: 17px;
    vertical-align: center;
    max-width: 140px;
    width: 53%;
    vertical-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/*Titulos de cada fila de la tabla de usuario*/
#titles{
    font-weight: 500;
    Font-family: Helvetica;
    Font-size: 16px;
    Vertical-align: center;
    color: #414141;
    width: 30%;
}
/*Titulo general de la tabla*/
#profile{
    font-weight: bold;
    Font-family: "Helvetica";
    Font-size: 18px;
    Vertical-align: center;
    color: #414141;
}
/*Columna que permite editar cada campo*/
#edit{
    color: #858585;
    vertical-align: center;
    border-color: #dee2e6;
    font-family: "Helvetica";
    font-size: 13px;
    width: 90px;
    cursor: pointer;
    padding-left: 6%;
}
/*Botón de guardar cambios*/
.EditProfile{
    background-color: #FFD52B;
    border: none;
    border-radius: 6px;
    width: 163px;
    height: 48px;
    font-family: "Helvetica";
    font-size: 16px;
    color: #495057;
    font-weight: bold;
    box-shadow: 0px 3px 4px lightgray;
}
/*Div que contiene el botón de guardar cambios*/
#centerButton{
    margin-top:35px;
    display: flex;
    justify-content: center;
}
/*Botón de ayuda*/
.Contact{
    background-color: #FFD52B;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;

}
/*Div que contiene el botón de ayuda al usuario*/
#helpButton{
    position: relative;
    display: flex;
    justify-content: right;
    padding: 10px;
    display: none;
}
/*Centrado del icono del botón de ayuda al usuario*/
i{
    margin-top:5px;
    border-width: 1px;
}

/*Div que contiene la imagen del usuario*/
#UserImage2{
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: inherit;
    height: 170px;
    background-color: transparent;
}
#ProfileImage2{
    margin-top: 0%;
    width: 170px;
    height: 170px;
    position: absolute;
    cursor:pointer;
    background-color: transparent;
}
/*Div que contiene el nombre completo del usuario*/
#UserName2{
    display:flex;
    justify-content: center;
    font-weight: bold;
    Font-family: "Roboto-Black";
    Font-size: 20px;
    color: #414141;

}
#Configuration,.projectName{
    font-weight: bold;
    Font-family: "Helvetica";
    Font-size: 22px;
    color: #414141;
    white-space: nowrap;
    max-width: 500px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
}
#subTitle{
    Font-family: "Helvetica";
    Font-size: 14px;
    color: #575252;
    font-weight: 300;
    margin-bottom: 0px;
}

#Provisional{
    width: 85%;
    border-bottom: 1px solid #FFD52B;
}
#FocusTitle{
    font-weight: bold;
    Font-family: Roboto;
    Font-size: 16px;
    Vertical-align: center;
    width: 30%;
    color: #FFD52B;
}
#FocusLine{
    border-bottom-color: #FFD52B;
}
#FocusEditable{
    font-family: "Noto sants";
    color: #414141;
}
#edit2{
    visibility: hidden;
}
#ErrorTitle{
    font-weight: bold;
    Font-family: Roboto;
    Font-size: 16px;
    Vertical-align: center;
    width: 30%;
    color: #BF2929;
}
#ErrorLine{
    border-bottom-color: #BF2929;
}
#password {
    -webkit-text-security: disc;
}

#error{
    color: #BF2929;
}
#valida{
    color: #414141;
}

#message{
    width: 2%;
    height: 3%;
    position: inherit;
    visibility: hidden;
}
#message2{
    width: 2%;
    height: 3%;
    position: inherit;
}
span a{
    color: #414141;
}
span a:hover{
    color: #414141;
}
#titleNotSelected{
    font-weight: 300;
    Font-family: Helvetica;
    Font-size: 16px;
    Vertical-align: center;
    color: #414141;
    width: 30%;
}
.subMenu{
    margin-left: 2%;
}
#titleNotSelected a:hover{
    color: #FFD52B;
}
#foto2{
    position: relative;
    display: flex;
    color: #414141;
    margin-top: 140px;
    margin-left: 85px;
    cursor: pointer;
    z-index: 999;
    background-color: white;
    border-radius: 90%;
    width: 30px;
    height: 30px;
    justify-content: center;
}
#fotoSize2{
    margin-top: 13%;
    margin-left: 5%;
}
#tituloContra{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-weight: 500;
    Font-family: "Roboto";
    Font-size: 18px;
    color: #414141;
    margin-bottom: 5%;
    margin-top: 15%;
}
.inputContra{
    width: 90%;
    margin-left: 4%;
    border-radius: 6px;
    border: 1px solid #C4C4C4;
    color: #C4C4C4;
    height: 40px;
    padding-left: 4.5%;
    font-weight: 100;
    font-size: 14px;
    font-family: "Noto sants";
    filter: drop-shadow(0 0 0 rgb(0, 0, 0, 0.08));
    color: #414141;
    font-weight: 300;
    -webkit-text-security: disc;
}
#contraButton{
    margin-top:50px;
    display: flex;
    justify-content: center;
    margin-bottom: 4%;
}

#centerTitleAvatar{
    display:flex;
    justify-content: center;
    font-weight: 500;
    Font-family: "Helvetica";
    Font-size: 14px;
    color: #414141;
    margin-bottom: 3%;
    margin-top: 1%;
}

#fotoAvatar{
    width: 114px;
    height: 114px;
}
#fotoAvatar2{
    width: 160px;
    height: 160px;
}
#avatares2{
   margin-left: 12%;
    justify-content: center;
    display: inline-block;
    cursor: pointer;
}

#fotoAvatarPrin{
    width: 142px;
    height: 142px;
    border: 2px solid #FFD52B;
    border-radius: 100%;

}
#fotoAvatarPrin2{
    width: 170px;
    height: 170px;
    border: 2px solid #FFD52B;
    border-radius: 100%;

}
#divFotoAvatar{
    margin-top: 7%;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
#avatares{
    margin-left: 21%;
    justify-content: center;
    display: inline-block;
    cursor: pointer;
    margin-right: 10%;
}

.SaveFoto{
    margin-top: 0%;
    background-color: #FFFFFF;
    border: 2px solid #FFD52B;
    border-radius: 6px;
    width: 163px;
    height: 48px;
    font-family: "Helvetica";
    font-size: 16px;
    color: #495057;
    font-weight: 600;
    
}

.SaveFoto:hover{
    background-color: #FFD52B;
    box-shadow: 0px 3px 4px lightgray;
}

#fotoAvatar:hover{
    border: 2px solid #FFD52B;
    border-radius: 100%;
}
#fotoAvatar2:hover{
    border: 2px solid #FFD52B;
    border-radius: 100%;
}
#errorLogin{
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 5px;
    color: #BF2929;
    display: none;
}


#errorLoginShow{
    margin-left: -65px;
    display: flex;
    justify-content: center;
    position: relative;
   margin-top: -40px;
    color: #BF2929;
}
#contenidoSubCapitulos{
    width: 1380px;
}
#construir0{
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: -5px;
    font-family: 'Roboto';
    cursor: pointer;
    color: #000000;
}
#construir0:hover{
   text-decoration: underline;
}


#principal{
    margin-bottom: 5%;
}
#bold{
    font-weight: bold;
    color: #414141;
}
#FocusEditableTitulo{
    font-family: "Noto sants";
    color: #414141;
    font-weight: bold;
}
.subMenu2{
    margin-right: 2%;
}


.selectCard:hover{
    background-color: #FDEFC6;
    cursor: pointer;
    transform: scale(1.1);
    transition: transform .2s;
}

.dark-grey {
    color: #414141;
}

.w-45px{
    width: 45px !important;
}

.w-16px{
    width: 16px !important;
}

.w-13px{
    width: 13px !important;
}

.h-13px{
    height: 13px !important;
}
.rotate180 {
    transform: rotate(180deg);
}

.cursorPointer {
    cursor: pointer;
}

@keyframes bounce {
    from{
        transform: scaleX(1.25);
    }
    to{
        transform: translateY(-50px) scaleX(1);
    }
}
