#NewCapi{
    width: 100%;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 0px;
    border-radius: 6px;
    background-color: white;
    min-width: 920px;

}

#NewCapiDanger{
    width: 98% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 0px;
    background-color: #F5D2D2;
    border-radius: 6px;
    min-width: 920px;

}

#positionActDropdown{
    left: 10px !important;
    top: 15px !important;
}

#NewSubCapi{
    width: 98.8% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 8px;
    background-color: #BDD9F7;
    border-radius: 6px;
    min-width: 920px;

}
#CapiModified{
    width: 98% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 0px;
    border-radius: 6px;
    background-color: #EAE6FD !important;
    min-width: 920px;

}
#CapiModified .NombreAlign{
    background-color: transparent !important;
    width: 270px;
}

#CapiModified:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}

.hoverCapitulo:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}

#CapiCreated{
    width: 98% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 0px;
    border-radius: 6px;
    background-color: #BDD3C2 !important;
    min-width: 920px;

}
#CapiCreated .NombreAlign{
    background-color: transparent !important;
    width: 270px;
}

#CapiCreated:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#CapiDeleted{
    width: 98% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 0px;
    border-radius: 6px;
    background-color: #F5D2D2 !important;
    min-width: 920px;

}
#CapiDeleted .NombreAlign{
    background-color: transparent !important;
    width: 270px;
}

#CapiDeleted:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewSubCapiDanger{
    width: 98.8% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:9px;
    margin-top: 8px;
    background-color: #F5D2D2;
    border-radius: 6px;
    min-width: 920px;

}

#NewSubCapi .NombreAlign{
    width: 270px;
}
#NewSubCapiSelect{
    width: 98.8% !important;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:3px;
    margin-top: 8px;
    background-color: #CCD8FC;
    border-radius: 6px;
    min-width: 920px;
}
#NewSubCapiSelect .NombreAlign{
    background-color: transparent;
    width: 270px;
}
#NewSubCapiDanger .NombreAlign{
    background-color: transparent;
    color: #BF2929;
    width: 270px;
}
#NewCapiDanger .NombreAlign{
    background-color: transparent;
    color: #BF2929;
    width: 270px;
}

#NewCapiSelect{
    width: 100%;
    
    border-radius: 0px;
    height: 44px;
    padding-top:0px;
    margin-bottom:0px;
    margin-top: 0px;
    background-color: #CCD8FC;
    border-radius: 6px;
    min-width: 920px;
    position: relative;
    opacity: 1;
    
}
#NewCapiSelect .NombreAlign{
    background-color: transparent;
}

.tablaPartidas{
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid #EFEFEF;

}
.tablaPartidas th {
    text-align: center !important;
    border-bottom: 1px solid #E9ECEF;
    height: 35px !important;
    margin:0px;
    padding: 0px;
    background: rgba(217, 217, 217, 0.2) !important;
}
.tablaPartidas tr td{
    border-left: 1px solid #B7B7B7;
    min-height: 35px !important;
}
.tablaPartidas tr td:first-child { border-left: none;  };

.tablaPartidas th{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #414141;
    text-align: center;
    border-left: 1px solid #B7B7B7;

}
.partidasInfo td {
    height: 35px;
}

#subCategory td{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #414141;
    height: 35px;
    text-align: center;
    border-bottom: 1px solid #B7B7B7;
    background: rgba(217, 217, 217, 0.2) !important ;


}

#lastPartida td{
    height: 35px;
    border-top: 1px solid #414141 !important;
    border-left: none;
    border-top: none !important;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color:#414141;
    padding-left: 60px;
    border-bottom: none !important;
}
#settingb:hover{
    background:none !important;
    color: white !important ;
    background-color: none !important;
}
#settingb{
    background:none !important;
    color: white !important ;
    background-color: none !important;
}
.dropdown-item:hover{
    background: #FFE992 !important;
}
#principalOptions p{
    width: 98%;
    font-family: "Helvetica";
    color: #414141;
    margin-bottom: 3.5px;
}
.partidasInfo p{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #414141;
} 
.tablaPartidas td{
    text-align: center;
    border-bottom: 1px solid #E9ECEF;
}
#hideSubCapitulos{
    display: none;
}
.hide{
    display: none;
}
.show{
    display: block;
}
.show #hideGrey{
    background-color: #CCD8FC;
}
#showSubCapitulos{
    display: block;
    width: 98.8%;
    background-color: #CCD8FC6B;
    /*margin-left: 24px;*/
    margin-bottom: 8px;
    margin-top: -10px;
}
.css-b8vq4d-MuiListItem-root:hover {
    background-color: #FDEFC6 !important;
}
#hideGrey{
    width: 79px;
    border-radius: 6px;
    height: 43px;
    margin-top: -10px;
    margin-left: -49px;
    padding-left: 78px;
    position: inherit;
    background-color: white;
}
#addSubcapinuevo{
    background-color: #CCD8FC;
    border-radius: 6px;
    width: 23px;
    height: 23px; 
    margin-top: 0px;  
    margin-bottom: 10px;
    font-size: 14px;
    padding-top: -20px;
    padding-left: 2px;
    justify-content: center;
    cursor: pointer;
}
#addSubcapinuevo:active{
    opacity: 1;
    background-color: grey;
    transition: 0s;
    transition-duration: 0.2s;

}

#precioTotal, .precioTotal{
    width: 110px;
    height: 28px;
    border: 1px solid #4461D7;
    box-sizing: border-box;
    border-radius: 6px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4461D7;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: -2px;
    padding-top: 2px;
}
#precioTotalFocus{
    width: 110px;
    height: 28px;
    border: 1px solid #4461D7;
    background-color: #4461D7;
    box-sizing: border-box;

    border-radius: 6px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: -2px;
    padding-top: 2px;
}
#precioTotal2{
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    height: 21px;
    border: 1px solid #414141;
    filter: drop-shadow(0px 4px 6px rgba(50, 50, 93, 0.11)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));
    border-radius: 6px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #414141;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-top: -1px;
}
#NumCapitulo{
    color: #414141;
    font-family: "Helvetica";
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
}

.MuiPagination-ul{
    justify-content: right;
    margin-top: 10px !important;
    margin-bottom: 24px !important;
}
.MuiPaginationItem-root.Mui-selected{
    background-color: #FFD52B !important;
}
.MuiPaginationItem-root:hover {
    background-color: #FFE992 !important;
}
.MuiPaginationItem-root{
    margin-right: 0px !important;
}

#subtitledelete{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #414141;
}

.MuiToggleButtonGroup-grouped:not(:last-of-type) {
border-radius: 6px !important;
border: 1px solid #414141 !important;
color: #414141 !important;
font-weight: 400 !important;
}

.MuiToggleButtonGroup-grouped:not(:first-of-type) {
border-radius: 6px !important;
border: 1px solid #414141 !important;
color: #414141 !important;
font-weight: 400 !important;
}
.MuiToggleButton-root.Mui-selected {
    border:0px !important;
    background-color: #414141 !important;
    color: white !important;
    font-weight: 700 !important;
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-disabled{
    border: 1px solid rgba(65, 65, 65, 0.5) !important;
    border-radius: 6px !important;
    color: rgba(65, 65, 65, 0.5) !important;
    font-weight: 400 !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input{
    height: 5px;
}

.PressSend-selected{
    border-radius: 6px !important;
    border:0px !important
}
#PressSend-grouped:not(:first-of-type){
    border-radius: 6px !important;
    border: 1px solid #4461D7 !important;
}
#PresSend:hover{
    background-color: #FFE992 !important;
}
.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
    color: #4461D7 !important;
}
.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: #4461D7 !important;
}

#titleHist{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    color: #B7B7B7;
}
.historico{
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
    border: 0.5px solid #B7B7B7;
 
}
#infoHis{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #414141;
    text-align: left;
    padding-left: 10px;
    }

.detalleHis {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #B7B7B7;
}
.TrHis{cursor: pointer;}
.TrHis:hover{background-color: #EFEFEF;}

.PartidaManual{
    background: #FBFBFB;

    border-radius: 6px;
    border: 0px;
    width: 223px;
    height: 24px;  
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #414141;
}
.PartidaManual:hover{
    background-color: #FDEFC6;

}
.textareaExpand {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    max-width: 690px !important;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }
  .textareaExpand2, .textareaExpand3{
    display: block;
    width: 100%;
    max-width: 690px !important;
    overflow: hidden;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #414141;
    line-height: 19.5px;
    resize: none;
    min-height: 20px;
    margin-top: 0px;
    border: 0px;
    justify-content:'left';
    text-align:'left';
    cursor: text;
  }


  .textareaExpand3{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #414141;
  }

  .textareaExpand5 {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    max-width: 304px !important;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }

  .textareaExpandEdit {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }
  .textareaExpandInvoice {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    max-width: 304px !important;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }

  .textareaP:focus-within {
    border: 1px solid #FFD52B !important
  }

  .textareaP:hover:not(:focus-within){
    border:1px solid #414141 !important
  }

  .partidaNameAutoExpandible {
   
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 400;
    font-size:14px;
    resize: none;
    color: #414141;
    border: 0px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
  }


  .textareaExpand10 {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    max-width: 100% !important;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }

  .newTextareaExpandEdit {
    padding-top: 13px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:12px;
    resize: none;
    min-height: 45px;
    margin-top: 0px;
    color: #414141;
    padding-left: 35px;
    padding-right: 35px;
    border: 0px;
    justify-content:'left';
    text-align:'left',
  }

  .textareaExpand:empty:focus::before, .textareaExpand:empty::before, .textareaExpand5:empty:focus::before,.textareaExpandEdit:empty:focus::before, .textareaExpand10:empty:focus::before, .textareaExpand5:empty::before, .textareaExpandEdit:empty::before{
        content: 'Introduce la descripción de la partida';
        color: lightgray;
  }
  .textareaExpand2:empty:focus::before, .textareaExpand2:empty::before{
    content: 'Introduce Titulo';
    color: lightgray;
}

.newTextareaExpandEdit:empty:focus::before, .newTextareaExpandEdit:empty::before{
    content: 'Escribir condiciones generales.';
    color: lightgray;
}
.textareaExpand3:empty:focus::before, .textareaExpand3:empty::before{
    content: 'Introduce Descripción';
    color: lightgray;
}
.textareaExpandInvoice:empty:focus::before, .textareaExpandInvoice:empty::before{
    content: 'Escribir';
    color: lightgray;
}
  .Show{
    display: none;
}
  #rotateimg180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  #rotateimg90 {
    -webkit-transform:rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .partidasInfo:hover td{
    border-top: 1px solid #E9ECEF !important;
    background-color: #FAFAFA;
  }

  .partidasInfo:hover #estiloBuscador{
    background-color: #FAFAFA;
  }
  .partidasInfo:hover #inputUnidades{
    background-color: #FAFAFA;
  }
 
  .partidasInfo:hover {
    box-shadow: 0px 4px 3px 0px rgb(1 0 37 / 7%);
    background-color: #FAFAFA;
  }
  .partidasInfo:hover .Show{
      display:flex;
      background-color: #FAFAFA;
  }

  .partidasInfo:hover .d-inline-block{
    background-color: #FAFAFA;
  }
.detallesCap{visibility: hidden;}

.partidasInfo:hover  .NombreAlign{
    color:#4461D7 ;
    background-color: #FAFAFA;
}
.partidasInfo:hover  td{
    border-left:none ;
    border-right: none;
}

.tdGuardarPartidas {
    padding-top: 100px;
}

.tdButtons {
    padding-top: 100px;
}

@media (max-height: 620px ){
    .tdGuardarPartidas {
        padding-top: 0px;
    }
    .tdButtons {
        padding-top: 0px;
    }
}

#NewCapi:hover, #NewCapiSelect:hover, #NewSubCapi:hover, #NewSubCapiSelect:hover,  #NewCapiDanger:hover, #NewSubCapiDanger:hover{
    box-shadow: 0px 4px 3px 0px rgb(1 0 37 / 7%);
    background-color: #B2C2FF;
    
}

#NewCapi:hover, #NewCapiSelect:hover, #NewSubCapi:hover, #NewSubCapiSelect:hover{
    background-color: #B2C2FF;
    
}


#NewCapi:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewCapiDanger:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewSubCapiDanger:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewCapiSelect:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewSubCapi:hover .detallesCap{
    visibility: visible;
    background-color: transparent !important;
}
#NewSubCapiSelect:hover .detallesCap{
    visibility: visible;
    background-color:  transparent !important;
}

#NewCapi:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color: transparent !important;
}
#NewCapiDanger:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color: transparent !important;
}
#NewSubCapiDanger:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color: transparent !important;
}
#NewCapiSelect:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color: transparent !important;
}
#NewSubCapi:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color: transparent !important;
}
#NewSubCapiSelect:hover .ChapterDragIcon{
    visibility: visible !important;
    background-color:  transparent !important;
}

.iconNewSide{
    width: 18px !important;
    min-width: 18px;
    height: 18px !important;
    margin-top: -17px;
    size: 20px;
    color: red;
    z-index: 444;
    margin-left: -25px;
}
.avisoChapters{
    width: 98%;
    margin-left: 24px;
    height: 40px;
    min-width: 1040px;
    padding-top: 10px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
    color: #414141;
    background-color: #F5D2D2;
    border-radius: 6px;
}
.letraPrecio {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #414141;
}

.tabOptions{
    width: fit-content;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    height: 24px;
    color: #FFFFFF;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 5px;
    padding-bottom: 11px;
    border-radius: 6px 6px 0px 0px;
    z-index: 99;
    cursor: pointer;
}


#updateRow.partidasInfo {
    background-color: #705DBE;
}

#updateRow.partidasInfo td{
    border-left: none !important;
    background-color: #EAE6FD;
}
#updateRow #settingb{
    background-color: #EAE6FD !important;
}
#updateRow #settingb:hover{
    background-color: #EAE6FD !important;
}
#updateRow .Show{
    background-color: #EAE6FD !important;
}
#updateRow #estiloBuscador{
    background-color: #EAE6FD !important;
}
#updateRow textarea{
    background-color: #EAE6FD !important;
}
#updateRow svg{
    background-color: #EAE6FD !important;
}
#updateRow #inputUnidades{
    background-color: #EAE6FD !important;
}

#createRow.partidasInfo {
    background-color: #BDD3C2;
}

#createRow.partidasInfo td{
    border-left: none !important;
    background-color: #BDD3C2;
}
#createRow #settingb{
    background-color: #BDD3C2 !important;
}
#createRow #settingb:hover{
    background-color: #BDD3C2 !important;
}
#createRow .Show{
    background-color: #BDD3C2 !important;
}
#createRow #estiloBuscador{
    background-color: #BDD3C2 !important;
}
#createRow textarea{
    background-color: #BDD3C2 !important;
}
#createRow svg{
    background-color: #BDD3C2 !important;
}
#createRow #inputUnidades{
    background-color: #BDD3C2 !important;
}

#deleteRow.partidasInfo {
    background-color: #F5D2D2;
}

#deleteRow.partidasInfo td{
    border-left: none !important;
    background-color: #F5D2D2;
}
#deleteRow #settingb{
    background-color: #F5D2D2 !important;
}
#deleteRow #settingb:hover{
    background-color: #F5D2D2 !important;
}
#deleteRow .Show{
    background-color: #F5D2D2 !important;
}
#deleteRow #estiloBuscador{
    background-color: #F5D2D2 !important;
}
#deleteRow textarea{
    background-color: #F5D2D2 !important;
}
#deleteRow svg{
    background-color: #F5D2D2 !important;
}
#deleteRow #inputUnidades{
    background-color: #F5D2D2 !important;
}
.CircleTeam{
    box-sizing: border-box;
    width: 57px;
    height: 57px;
    background: #F5F5F5;
    border: 1px solid #B7B7B7;
    border-radius: 100px;
    margin-right: 11px;
    margin-top: -17px;
    margin-left: 1px;
    padding: 12px;
    border: 1px solid #B7B7B7;
    font-size: 20px;
    text-transform: uppercase;
    justify-content: center;
    text-align: center;
    color: black;
    font-weight: 500;
    font-family: Helvetica;
}

.CommentsTextField{
    width: 100%;
    bottom: -24px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #414141;
}

.MineComment{
    width: 100%;
    text-align: right;
    justify-content: right;
    display: flex;
    position: relative;
    margin-bottom: 30px;
    right: 85px;
}
.OtherComment{
    width: 100%;
    text-align: left;
    justify-content: left;
    display: flex;
    position: relative;
    margin-bottom: 30px;
    left:85px;
}
.UpBar{
    display: flex;
    padding-left: 20px;
    margin-bottom: -5px;
    margin-top: 10px;
}
.totalofComments{
    box-sizing: border-box;
    width: 671px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
}
.AuthorComment{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #FC6E6E;
}

.upBarComment{
    padding-top: 2px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #414141;
}

.messageContent{
    position: relative;
    width: 100%;
    text-align: justify;
    height: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #414141;
    padding-right: 20px;
    ;
}

.Circulo{
    border-radius: 100px;
    background-color: #FC6E6E;
    width: 46px;
    height: 46px;
    right: 20px;
    margin-top: 3px;
    position: fixed;
}

.Circulo2{
    border-radius: 100px;
    background-color: #FC6E6E;
    width: 46px;
    height: 46px;
    left: 20px;
    margin-top: 3px;
    position: fixed;
}




.nuevoBotonCrear{
    background: transparent;
    border: 0px ;
    border-radius: 6px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #414141;
    cursor: pointer;
    width: 180px;
    text-align: left;
}
.nuevoBotonCrear4{
    background: transparent;
    border: 0px ;
    border-radius: 6px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #414141;
    cursor: pointer;
    width: 180px;
    text-align: left;
}
.nuevoBotonCrear4:hover{
    background: #414141;
    border: 0.5px solid #414141;
    border-radius: 6px;
    color: white;
}
.nuevoBotonCrear:hover{
    background: #E9ECF8;
    border: 0.5px solid #86A3FA;
    border-radius: 6px;
}

.nuevoBotonCrear2{
    background: transparent;
    border: 0px ;
    border-radius: 6px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #414141;
    cursor: pointer;
    width: 150px;
    text-align: left;
}

.nuevoBotonCrear3:hover{
    background: white;
    border: 0.5px solid #FEE398;
    border-radius: 6px;
}

.nuevoBotonCrear3{
    background: transparent;
    border: 0px ;
    border-radius: 6px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #414141;
    cursor: pointer;
    width: 180px;
    text-align: left;
}

.nuevoBotonCrear2:hover{
    background: #b4aa8d;
    border: 0.5px solid #F1CA2C;
    border-radius: 6px;
}

.cambioContra{
    background: transparent;
    border: 1px solid #C4C4C4 ;
    border-radius: 6px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #C4C4C4;
    cursor: pointer;
    width: 140px;
    text-align: left;
}

.cambioContra:hover{
    background: #E9ECF8;
    border: 1px solid #86A3FA;
    border-radius: 6px;
    color: #414141
}

.detallesPartiShow{
    display: none;
}
.detallesPartiShow2{
    visibility: hidden;
}

.partidasInfo:hover .detallesPartiShow{
    display: block;
}
.partidasInfo:hover .detallesPartiShow2{
    visibility: visible;
}


.separator{
    width: auto;
    height: 1px;
    background-color: #F5F5F5;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    position: relative;
}


#downloadButton:hover{
    background-color: #ffe992;
}

#selectFastAction{
    border-radius: 6px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
}

#selectFastAction:hover{
    background-color: #FFE992;
}

.errorTitulo{
    border-top: 1px solid #BF2929 !important;
    border-bottom: 1px solid #BF2929 !important;
    border: 1px solid #BF2929 !important;
    border-color: #BF2929 !important;
}