// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active{
    display: block;
}

.carousel{
    .carousel-inner{
       height: 100vh;
    }
    .nc-icon{
        font-size: 2em;
    }
}

.carousel-item.active img{
    height: 100vh;
}
.carousel-item img{
    height: 100vh;
}

@media screen  and (max-height: 730px) {
    .carousel{
        .carousel-inner{
           height: 120vh;
        }
        .nc-icon{
            font-size: 2em;
        }
    }
    
    .carousel-item.active img{
        height: 120vh;
    }
    .carousel-item img{
        height: 120vh;
    }
}

@media screen  and (max-height: 620px) {
    .carousel{
        .carousel-inner{
           height: 150vh;
        }
        .nc-icon{
            font-size: 2em;
        }
    }
    
    .carousel-item.active img{
        height: 150vh;
    }
    .carousel-item img{
        height: 150vh;
    }
}

@media screen  and (max-height: 500px) {
    .carousel{
        .carousel-inner{
           height: 170vh;
        }
        .nc-icon{
            font-size: 2em;
        }
    }
    
    .carousel-item.active img{
        height: 170vh;
    }
    .carousel-item img{
        height: 170vh;
    }
}

@media screen  and (max-width: 960px) {
    .carousel{
      display: none;
    }
    .recuadroGeneralAuth{
        width: 100%;
        justify-content: center;
    }
    #AquiCarousel{
        width: 0px;
    }
}
@media screen  and (max-width: 450px) {
    .carousel{
      display: none;
    }
    .recuadroGeneralAuth{
        width: 100%;
        justify-content: center;
    }
    #AquiCarousel{
        width: 0px;
    }
    #inputsLogin, #inputsLogin:focus{
        width: 320px;
    }
    .buttonAuth{
        width: 320px;
    }
    #OpenGoogle, .solicitarAcceso{
        width: 320px;
    }
    #visibilityOn, #visibilityOff{
        margin-left: 275px;
    }
    img{
        max-width: 320px;
    }
    #centerRecuadro{
        margin-left: 0px;
    }
}