#TituloBudget{
    font-family: "Helvetica";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #414141;
}
#idTablaBudget{
    width: 100%;
    margin-top: 80px;
    overflow-x: auto;
    margin-left: 15px;
    margin-right: 15px;
}
#TablaBudget{
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    margin-left: 50px;
    margin-right: 50px;
    margin-top:80px;
    max-height: 500px;
    height: 500px;
}
#TablaBudget th{
    border-bottom: 0.8px solid #C4C4C4;
}
#TablaBudget tr{
    border-bottom: 0.8px solid #C4C4C4;
}
#TablaBudget td{
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size: 13px;
    color: #414141;
    height: 50px;
}
.checkColumn{
    min-width: 30px;
    padding-bottom:10px;
    padding-left: 2px;
}
.thCapitulo{
    min-width: 450px;
    padding-bottom: 10px;
}
.thEtiquetas{
    min-width: 750px;
    padding-bottom: 10px;
}
.thTotalCap{
    min-width: 150px;
    padding-bottom: 10px;
}
.tdcheckColumn{
    min-width: 30px;
    padding-left: 2px;
    border-bottom: 0px;
}
.tdCapitulo{
    min-width: 450px;
}
.tdEtiquetas{
    min-width: 215px;
    width: 52%;
}


.tdTotalCap{
    min-width: 150px;
    width: 100%;
}
input[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    border-color:black;
    background-color: #4461D7;
}
.checkboxChapter {
    display: none;
}
.dropdownArrowB{
    margin-left: -5px;
    margin-right: 10px;
    margin-top: -2px;
}
.DetallesPartida{
    height: 50px;
    display: none;
}
.DetallesPartidaShow td{
    padding-left: 30px;
}
.DetallesPartidaShow {
  
    display: block;
}
#SubPartidaTitulo{
    padding-top: 15px;
    padding-left: 30px;
    font-family: "Helvetica";
    font-weight: 500;
    height: auto;
    margin-left: 1px;
}
.AddPartida{
font-family: "Helvetica";
font-weight: 500;
font-size: 14px;
align-content: center;
position: relative;
display: flex;
justify-content: center;
width: 229px;
height: 33px;
border: 1px solid #FFD52B;
box-sizing: border-box;
border-radius: 8px;
color: #414141;
background-color: white;
padding-top: 5px;
margin-bottom: 68px;
margin-top:49px;
}
#AddEtiquetaBudget{
    color:#414141;
    width: 22px;
    height: 22px;
}
.NombreAlign{
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    margin-top: 2px;
    color: #414141;
    background-color: transparent;
    position: relative;
    display: inline;
    border: 0px;
    width: 400px;
    margin-left: -15px;
    padding-left: 15px;
}

.filaSelected{
    background-color: #EEEEEE;
    border-top: 2px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE !important;
}
.filaSelected .NombreAlign{
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size: 15px;
    color: #414141;
    position: relative;
    display: inline;
    border: 0px;
    width: 400px;
    background-color: #EEEEEE;
    margin-left: -15px;
}
#addtagbudget{
    width: 320px;
    height: 40px;
    z-index: 2;
    margin-left: 34px;
    margin-top: 9px;
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px lightgrey;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}
#tagBudget{
    margin-left: 13px;
    position: absolute;
    z-index: 3;
    margin-top: 8px;
    width: 20px;
    height: 24px;
}
#NOaddtagbudget{
    width: 320px;
    height: 40px;
    position: absolute;
    z-index: 2;
    margin-left: 27px;
    margin-top: -18px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px lightgrey;
    display: none;
}
#addtagbudget input{
    padding-left: 35px;
    
}
#closeTags{
    float: right;
    display: flex;
    position: relative;
    margin-top: -30px;
    margin-right: 7px;
    width: 20px;
    height: 20px;
}
#displayInline{
    position: relative;
    display: inline-flex;
}
#nuevasEtiquetas{
    position: relative;
    display: inline-flex;
    padding-top:5px;
}
#extraTag{
    font-family: "Helvetica";
    font-weight: 300;
    font-size: 12px;
    color: #C4C4C4;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: none;
}
#extratagshow{
    font-family: "Helvetica";
    font-weight: 300;
    font-size: 12px;
    color: #C4C4C4;
    padding-top: 12px;
    padding-left: 5px;
    padding-right: 5px;
    width: 40px;
}
#subCapis{
    font-size: 14px;
    margin-bottom: 15px;
    
}
#addPartida{
    background-color: #FFD52B;
    width: 30px;
    height: 30px;
    padding-left: 2px;
    padding-top: 2px;
    color: white;
    border: 2px solid #FFC727;
    box-sizing: border-box;
    border-radius: 100px;
    margin-right: 8px;
    justify-content: center;
    position: relative;
    margin-top: 5px;
}

#botonContentBudget{
    width: 22px;
    height: 22px;
    color: black;
}
#botonesSubCapitulos{
    position: relative;
    display: inline-flex;
    bottom: -50px;
    width: 100%;
    justify-content: center;
}
.addPartidaDisabled{
    width: 154px;
    height: 38px;
    background-color: #EEEEEE;
    border-radius: 100px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #D1D1D1;
    border: 0;
    margin-top: 1px;
    margin-left: 8px;
}
.AddPartidaSave{
    width: 154px;
    height: 38px;
    background-color: #FFD52B;
    border-radius: 100px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #414141;
    border: 0;
    margin-top: 1px;
    margin-left: 8px;
}
.PresupuestoTotal{
    width: 147px;
    height: 53px;
    background-color: white;
    border: 1px solid #4461D7;
    box-sizing: border-box;
    border-radius: 6px;
    color: #414141;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 13px;
    justify-content: center;
    text-align: center;
    padding-right: 22px;
    padding-left:22px;
    margin-left: 22px;
    margin-top:-7px;
}
#descripcionbud{
    width: 50px;
    height: 50px;  
    white-space: pre;
    word-break: break-word; 
}
#addPartida2{
    margin-top:-2px;
    background-color: #FFD52B;
    width: 25px;
    height: 25px;
    padding-left: 1px;
    padding-top: 0.5px;
    color: white;
    border: 2px solid #FFC727;
    box-sizing: border-box;
    border-radius: 100px;
    margin-right: 8px;
    justify-content: center;
    position: relative;
}
#botonContentBudget2{
    width: 19px;
    height: 19px;
    padding-left: 1px;
    color: black;
}
#areaBud{  
    overflow: hidden;
    position: absolute;
    width: 400px;
    font-size: 14px;
    display: block;
    font-family: 'Helvetica' !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #414141 !important;
    border-color: white;
    height: 20px!important;
    padding-left: 0px;
    padding-top:7px;
}
#addParti{
    width: 106px;
    height: 29px;
}
#infoIcon{
    width: 24px;
    height: 24px;
    color: #E5E5E5;
    margin-top: -2px;
}
#putNewParti{
    position: absolute;
    margin-right: 243px;
    margin-top: -30px;
    display: none;
}
#putNewPartiShow{
    position: absolute;
    margin-right: 85px;
    margin-top: -24px;
}
#contenidoSubCapitulos{
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
}
#NOcontenidoSubCapitulos{
    display: none;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
}
.detallesTabla{
    margin-top:45px;
    margin-bottom:45px;
    border: 0;
    border-bottom:0;
}
.detallesTabla td {
    padding-left: 0px;
    padding-top: 10px;
    border-bottom:0;
    font-family: 'Helvetica' !important; 
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #414141 !important;
}
.detallesTabla th{
    border-bottom:0px !important;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #414141;
}
.detallesTabla tr{
    border-bottom:0px !important;
}
#descripcionbud{
    max-width: 420px;
    height: auto;
    display: block;
    overflow: auto;
    font-family: 'Helvetica' !important; 
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #414141 !important;
    border-color: white;
    height: 20px!important;
    padding-left: 0px;
    padding-top:7px;
}
#botonesPrincBudget{
    float: right;
    justify-content: flex-end;
    display: flex;
    margin-top: -45px;
    width: 80%;
    margin-right: -15px;
}
#costPress{
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    background-color: white;
    width: 175px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #FFD52B;
    margin-right: 7.4px;
}
#costPress:hover{
    background-color: #FFE992 !important;
}
#costPressFin:hover{
    background-color: #FFE992 !important;
}
#provePressFin:hover{
    background-color: #FFE992 !important;
}
#costPress:active, #costPressFin:active, #provePressFin:active {
    background-color: #F1CA2C !important;
}
#costPressFin{
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    background-color: white;
    width: 160px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #FFD52B;
    margin-right: 7.4px;
}
#provePressFin{
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    background-color: white;
    width: 135px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #FFD52B;
    margin-right: 7.4px;
}
#settingsBud{
    margin-right: 7.4px;
    margin-top: 3.5px;
    transition: transform .7s ease-in-out;
}
#settingsBud:hover{
    transform: rotate(180deg);
}
#iconSend{
    width: 15px;
    height: 15px;
    margin-top:-1px;
}
#sendButton{
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    background-color: white;
    width: 92px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #FFD52B;
    margin-right: 7.4px;
}
#downloadIcon{
    width: 15px;
    height: 15px;
    margin-top:-1px;
}
#downloadButton{
    font-family: "Helvetica";
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    background-color: white;
    width: 118px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #FFD52B;
}
.css-10hburv-MuiTypography-root{
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 13px !important;
}
.css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    padding: 0px !important
}
#estiloBuscador{
    width: 100%;
    height: 36px;
    margin-top: -3px;
    height: auto;
    margin-top: 2px;
}
#estiloBuscador .NombreAlign{
    width: 90% !important;
}
#lupaBud{
    color: #000000;
    width: 18px;
    height: 18px;
    margin-left: 5px;
}
#inputUnidades {
    font-family: Helvetica;
    height: 35px;
    width: 80%;
    justify-content: center;
    text-align: center;
    border: none;
}
.css-vvi6wm-MuiList-root {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 373px;
    background-color: #fff;
    position: absolute;
    overflow: auto;
    max-height: 300px;
    border-radius: 8px;
    z-index: 3;
    border: 1px solid #e0e0e0;
}
#delParti{
    position: absolute;
    margin-right: 435px;
    margin-top: -23px;
    display:none;
}
#delPartiShow{
  position: absolute;
    margin-right: 274px;
    margin-top: -24px;

}
#centerTitleEliminar{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 100px;
    color: #414141;
    margin-bottom: 4%;
    margin-left: -3px;
    margin-right: -12px;
}
.CancelDel{
    margin-top: 0%;
    background-color: #FFFFFF;
    border: 2px solid #FFD52B;
    border-radius: 30px;
    width: 163px;
    height: 48px;
    font-family: "Helvetica";
    font-size: 16px;
    color: #495057;
    font-weight: 600;
}
.CancelDel:hover{
    background-color: #FFE992;
    border: 1px solid #FFD52B;
}
.CancelDel:active{
    background-color: #F3C816;
    border: 1px solid #FFD52B;
}
#totalPres{
    position: fixed;
    bottom: 0px;
    width: 244px;
    height: 48px;
    margin-left: 25px;
    z-index: 4;
    background-color: #FFFFFF;
    border: 1px solid #4461D7;
    border-bottom: 0px;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 0px 20px -3px lightgrey;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
#flechaArribaPres{
    margin-right: 5px;
    margin-top: -3px;
    width: 26px;
    height: 26px;
    cursor: pointer;
}
#desglosePres{
    width: 100%;
    position: fixed;
    margin-left: -24px;
    z-index: 1000;
    background-color: white;
    position: fixed;
    visibility: hidden;
    bottom: 0px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    height: 113.33px;
    border-top: 2px solid #4461D7;
    animation: desgloseDown 0.5s linear;
    transition: .0.5s ease-in-out;
}
#desglosePres2{
    width: 100%;
    position: fixed;
    margin-left: -24px;
    z-index: 1000;
    background-color: white;
    position: fixed;
    visibility: hidden;
    bottom: 0px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    height: 113.33px;
    border-top: 2px solid #4461D7;

}
#desglosePresShow{
    width: 100%;
    position: fixed;
    margin-left: -30px;
    z-index: 1000;
    background-color: #EFEFEF;
    bottom: 0px;
    height: 70px;
    border-top: 1px solid #C4C4C4;
    display: flex;
    overflow: hidden;
    animation: desgloseUp 0.5s linear;
    transition: .0.5s ease-in-out;
}
.cuadMargins{
    width: 66px;
    height: 40px;
    background: #707070;
    border-radius: 6px;
    justify-content: center;
    text-align: center;
    display: flex;
    position: absolute;
    top:15px;
    left:25px;
    cursor: pointer;
}

@keyframes desgloseUp {
    from {
      height: 0px;
    }
    to {
      height: 113.33px;
    }
  }

  @keyframes desgloseDown {
    from {
      height: 113.33px;
      visibility: visible;
      display: flex;
    }
    to {
      height: 0px;
      display: none;
      
    }
  }

#PresCoste{
    display: flex;
    justify-content: right;
    align-items: center;
    width: 540px;
    height: 103px;
    border-right: 1px solid#C4C4C4;
}
#flechaAbajoPres{
    margin:10px;
    width: 29px;
    height: 29px;
    cursor: pointer;
}
.totalE{
    font-family: 'Arial';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #414141;
    justify-content: right;
    display: flex;
    margin-top: -20px;
}
.subTitE{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #414141;
    justify-content: right;
    display: flex;
    margin-top: -25px;
}
.seeMoretags{
    height: 40px;
    z-index: 2;
    margin-left: 150px;
    margin-top: 15px;
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px lightgrey;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    min-width: 400px;
    width: auto;
    background-color: white;
   display: flex;
}
.NOseeMoretags{
    height: 40px;
    z-index: 2;
    margin-left: 150px;
    margin-top: 15px;
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px lightgrey;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    min-width: 300px;
    width: auto;
    background-color: white;
    display: None;
   
}
#closeTags2{
    float: right;
    display: flex;
    position: absolute;
    margin-top: -30px;
    margin-right: 7px;
    width: 20px;
    height: 20px;
    float: right;
    right: 0px;
}
#lastUpdate{
    position: absolute;
    justify-content: center;
    display: flex;
    margin-top: 23px;
    margin-left: 466px;
    color: #C4C4C4;
}
#autoSave{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #4461D7;
    padding-TOP: 9px;
}
.hide{
    display: none;
}

.loader{
    margin-top: 100px;
    width: 120px;
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-left: 135px;
}
.ball{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #5076FF;
    animation: bounce .5s alternate infinite;
}

.ball:nth-child(2){
    animation-delay: .16s;
}

.ball:nth-child(3){
    animation-delay: .32s;
}

@keyframes bounce {
    from{
        transform: scaleX(1.25);
    }
    to{
        transform: translateY(-50px) scaleX(1);
    }
}

#interFont{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
margin-left: 4px;
color: #FFFFFF;
}

.boxWithShadow{
    width: 204.67px;
    height: 148px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
.placeholderCenter::placeholder, .placeholderCenter::-webkit-input-placeholder {
    text-align: center;
    color: #C4C4C4 !important;
    -webkit-text-fill-color: #41414180 !important;
}