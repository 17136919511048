.selected {
  background-color: #fdefc6;
}


.projectRow {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  cursor: pointer;
}

.projectRow:hover {
  background-color: #fdefc6;
}

#capitalize {
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0px;
  text-overflow: ellipsis;
}

#capitalize:first-letter {
  text-transform: uppercase;
}